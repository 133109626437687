
import { computed, ComputedRef, defineAsyncComponent, defineComponent, onMounted, ref } from "vue";
import { AxiosResponse } from "axios";
import apiAdmin from "@/api/spaceMountainAdminAPI";
import apiClient from "@/api/spaceMountainAPI";
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";
import Input from "@/types/Input";
import {getForm} from "@/functions/forms";

export default defineComponent({
  name: "Editions",
  components: {
    Button: defineAsyncComponent(() => import("@/components/Button.vue")),
    Header: defineAsyncComponent(() => import('@/components/Header.vue')),
    Modale: defineAsyncComponent(() => import('@/modales/Modale.vue')),
    FormTemplate: defineAsyncComponent(() => import('@/forms/FormTemplate.vue')),
    Loader:  defineAsyncComponent(() => import('@/components/Loader.vue')),
    CardButton:  defineAsyncComponent(() => import('@/components/CardButton.vue')),
  },
  props: {
    formId: { type: String, default: '' },
    readOnly: { type: Boolean, default: false }
  },
  setup( props, { emit }) {
    //STORE user
    const usersStore = useUserStore();
    const { user } = storeToRefs(usersStore);

    //DATA
    const step = ref(0);
    const idTech = ref(props.formId);
    const loader = ref(true);
    const loaderMsg = ref('Récupération des données');

    //V-MODEL
    const model = ref({
      reporting: [
        { type: "text", required: true, placeholder: "Nom de l'édition", field: "nomEdition", value: '', rules : [{ msg: 'Ce champ est obligatoire', regexp: /^[\s\t\r\n]*\S+/ig, isValid: false }] },
        { type: "textarea", required: false, placeholder: "Liste des colonnes souhaitées et explications détaillée de chaque colonne (Il faudrait nous joindre un exemple de fichier svp) :", field: "detailEdition", value: '', rules : [{ msg: 'Ce champ est obligatoire', regexp: /^[\s\t\r\n]*\S+/ig, isValid: false }] },
        { type: "uploadFile", required: true, placeholder: "Fichier joint", field: "fichierJoint", value: [] }
      ]
    });

    const formToSend: ComputedRef<Object> = computed(() => {
      let result: any = {
        reporting: {},
      };
      model.value.reporting.forEach((input: any) => {
        if (input.value !== '') result.reporting[input.field] = input.value;
      });
      return result;
    });

    const saveForm = () => {
      loaderMsg.value = "Sauvegarde des données";
      loader.value = true;
      step.value = 1;
      apiClient.saveForm(idTech.value, formToSend.value).then(() => {
        loader.value = false;
        emit('save');
      });
    };

    const submitForm = () => {
      loaderMsg.value = "Soumission du formulaire";
      loader.value = true;
      apiClient.submitForm(idTech.value, formToSend.value).then(() => {
        loader.value = false;
        emit('save');
      });
    };

    const api = () => {
      if (user.value.role ==='admin' || user.value.role ==='superAdmin') return apiAdmin;
      else return apiClient;
    };

    onMounted(() => {
      api().getForm(idTech.value).then((response: AxiosResponse) => {
        getForm(response, model.value);
        loader.value = false;
      });
    });

    return { step, model, submitForm, saveForm, formToSend, loader, loaderMsg }
  },
});
